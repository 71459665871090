<template>
  <b-card>
    <div>
      <!-- search input -->
      <div class="custom-search d-flex align-items-center justify-content-end">
        <div
          class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
        >
          <template v-if="$permissionAbility(DIVISION_CREATE, permissions)">
            <b-button
              class="flex-shrink-0 btn-md mr-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-on:click="restoreSelected"
              :disabled="!isRowChecked"
            >
              Restore Selected
            </b-button>
          </template>
          <template v-if="$permissionAbility(DIVISION_CREATE, permissions)">
            <b-button
              class="flex-shrink-0 btn-md"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              v-on:click="deleteSelected"
              :disabled="!isRowChecked"
            >
              Delete Selected
            </b-button>
          </template>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
        mode="remote"
        styleClass="vgt-table table-custom-style striped"
        @on-selected-rows-change="selectionChanged"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords"
        :isLoading.sync="isLoading"
        :rows="rows"
        :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [{ field: 'created_at', type: 'desc' }],
        }"
        :select-options="{ enabled: true }"
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- format_members -->
          <template v-if="props?.column?.field === 'format_title'">
            <div class="table-cell-margin">
              <span class="font-weight-bold">{{ props?.row?.title }}</span>
            </div>
          </template>

          <span v-if="props.column.field === 'format_type'">
            <b-badge
              :variant="props?.row?.is_final ? 'light-danger' : 'light-primary'"
            >
              {{ props?.row?.is_final ? "Final" : "General" }}
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">

            <feather-icon
                v-on:click="restore(props?.row?.id)"
                v-b-tooltip.hover
                icon="RefreshCcwIcon"
                class="mr-50 custom-icon cursor-pointer"
                title="Restore"
                size="16"
            />

            <feather-icon
                v-on:click="onDelete(props.row.id)"
                v-b-tooltip.hover
                icon="TrashIcon"
                class="mr-50 custom-icon cursor-pointer"
                title="Delete Permanently"
                size="16"
            />

          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '25', '50', '100', '500']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BFormCheckboxGroup,
  BFormCheckbox,
  BLink,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import {
  DIVISION_CREATE,
  DIVISION_EDIT,
  DIVISION_DELETE,
} from "@/helpers/permissionsConstant";

export default {
  name: "DivisionTrashView",
  components: {
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BFormCheckboxGroup,
    BFormCheckbox,
    BLink,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      DIVISION_CREATE,
      DIVISION_EDIT,
      DIVISION_DELETE,
      // modelType: "",
      // divisionId: "",
      // name: "",
      // selectHeadId: "",
      // headIdOption: [],
      pageLength: 10,
      columns: [
        {
          label: "Title",
          field: "format_title",
          sortable: false,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      selectedRows: [],
      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      isRowChecked: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [{ field: "created_at", type: "desc" }],
        page: 1,
        perPage: 10,
      },
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  methods: {
    selectionChanged(params) {
      if (params?.selectedRows.length == 0) {
        this.isRowChecked = false;
        this.selectedRows = [];
      } else {
        this.isRowChecked = true;
        this.selectedRows = params?.selectedRows;
      }
    },
    async restore(id) {
      const milestoneTaskBoardId = this.$route.params.id;

      try {
        await this.$api.put(`api/milestone-task-boards/tasks/${id}/restore`);

        this.loadItems();

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "BellIcon",
            variant: "success",
            text: "Successfuly Restored",
          },
        });
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    async onDelete(id) {
      this.$swal({
        title: "Warning!",
        text: "This action is Irreversible! Are you sure to delete this permanently?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const milestoneTaskBoardId = this.$route.params.id;

            await this.$api.delete(
              `api/milestone-task-boards/tasks/${id}/force-delete`
            );

            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "This record has been permanently deleted ",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    async restoreSelected() {
      const taskIds = (this.selectedRows || []).map((item) => {
        return item?.id;
      });

      try {
        const milestoneTaskBoardId = this.$route.params.id;

        await this.$api.put(
          `api/milestone-task-boards/tasks/restore-multiple`,

          {
            ids: taskIds,
          }
        );

        this.loadItems();

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "BellIcon",
            variant: "success",
            text: "Selected records successfuly restored",
          },
        });
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    async deleteSelected() {
      const taskIds = (this.selectedRows || []).map((item) => {
        return item?.id;
      });

      this.$swal({
        title: "Warning!",
        text: "This action is irreversible! Are you sure to delete these records permanently?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const milestoneTaskBoardId = this.$route.params.id;
            const formData = new FormData();

            formData.append("_method", "DELETE");

            if (taskIds.length > 0) {
              taskIds.forEach(function (element) {
                formData.append("ids[]", element);
              });
            }

            await this.$api.post(
              `api/milestone-task-boards/tasks/force-delete-multiple`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Selected records permanently deleted",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },
    async getTrashedTasks(params) {
      const milestoneTaskBoardId = this.$route.params.id;
      return await this.$api.get(
        `api/milestone-task-boards/${milestoneTaskBoardId}/tasks/trashed`,
        {
          params: {
            show: params.show,
            page: params.page,
            sort: params.sort,
            q: params.q,
          },
        }
      );
    },

    async loadItems() {
      try {
        const [tashedTasks] = await Promise.all([
          this.getTrashedTasks({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
          }),
        ]);

        const data = tashedTasks?.data?.data;
        const meta = tashedTasks?.data?.meta;

        this.totalRecords = meta?.pagination?.total;
        this.rows = data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.table-custom-style {
  font-size: 13px !important;
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: left !important;
    text-align: left !important;
  }
}
.table-cell-margin {
  margin: 8px 0px;
}
</style>
